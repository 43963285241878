<script setup>
import pagesData from '@/assets/pagesData.json';
import memberData from '@/assets/memberData.json';
import HomeCategoryContainer from '@/components/HomeCategoryContainer.vue';
import TeamBio from '@/components/TeamBio.vue';
import { computed } from 'vue';

const categorizedPagesData = computed(() => {
  const categories = {};

  for (const key in pagesData) {
    const item = pagesData[key];
    if (!categories[item.category]) {
      categories[item.category] = [];
    }
    categories[item.category].push(item);
  }

  return categories;
});
</script>

<template>
  <div class="flex justify-center">
    <div class="max-w-7xl w-full py-10 px-8 md:px-24 flex flex-col gap-16">
      <HomeCategoryContainer
        v-for="(category, key) in categorizedPagesData"
        :key="key"
        :category="category"
        :name="key"
      />
      <hr class="border-black" />
      <div>
        <h3 class="text-center font-bold text-4xl mb-8">
          MarTech Development Team
        </h3>
        <div class="grid grid-cols-1 lg:grid-cols-2 mx-auto gap-10">
          <TeamBio
            v-for="(member, index) in memberData"
            :key="index"
            :member="member"
          />
        </div>
      </div>
    </div>
  </div>
</template>

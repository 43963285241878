<script setup>
import { ref } from 'vue'
import ArrowRightIcon from '@/components/icons/ArrowRightIcon.vue'

const videoPlayer = ref(null)
</script>

<template>
  <section class="grid grid-cols-3 lg:grid-cols-6 gap-10 mt-10">
    <div class="col-span-3 flex flex-col gap-4 justify-center">
      <h3 class="font-bold text-3xl">Enhanced Engagement</h3>
      <p class="mb-4">Personalized videos <strong>capture attention</strong> more effectively by addressing viewers directly. This leads to <strong>higher engagement rates</strong>, as viewers are more likely to watch personalized content longer than generic content, making it a powerful tool for maintaining their interest and conveying your message.</p>
      <div class="bg-gradient-to-r from-sd-bg from from-50% bg-[length:200%_100%] via-50% bg-left via-sd-blue to-sd-light-blue hover:bg-right transition-all duration-200 w-fit bg-clip-text text-lg">
        <p @click="videoPlayer.play()" class="text-transparent font-bold cursor-pointer inline-flex items-center gap-2">Play Example <ArrowRightIcon class="text-sd-bg" /></p>
      </div>
    </div>
    <div class="col-span-3 mb-2">
      <video ref="videoPlayer" controls>
        <source src="https://player.vimeo.com/progressive_redirect/playback/989530457/rendition/1080p/file.mp4?loc=external&log_user=0&signature=2761cb0a795722206ccc6e164871ee05f7b0abe43ceb294560786c16264a043f" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
    <div class="col-span-3 lg:col-span-2">
      <h3 class="font-bold text-2xl mb-2">Brand Understanding</h3>
      <p class="mb-4">
        Personalization shows that a brand
        <strong>understands and values its customers</strong>. This leads to an enhanced customer experience and <strong>fosters loyalty</strong>, strengthening the relationship between the brand and its customers.
      </p>
    </div>
    <div class="col-span-3 lg:col-span-2">
      <h3 class="font-bold text-2xl mb-2">Higher Retention Rates</h3>
      <p class="mb-4">Personalized videos are more <strong>memorable and engaging</strong>, helping to retain customer interest. There is also a <strong>reduced likelihood of audience drop-off</strong>, ensuring that viewers stay engaged throughout the entire video.</p>
    </div>
    <div class="col-span-3 lg:col-span-2">
      <h3 class="font-bold text-2xl mb-2">Click-Through Rates</h3>
      <p class="mb-4">
        Personalized video thumbnails and calls-to-action (CTAs) are
        <strong>more likely to attract clicks</strong>. This leads to <strong>higher traffic and engagement</strong> on desired web pages, driving more interaction and potential conversions.
      </p>
    </div>
    <div class="col-span-3 mb-2">
      <video controls>
        <source src="https://player.vimeo.com/progressive_redirect/playback/989530514/rendition/720p/file.mp4?loc=external&log_user=0&signature=6d00cefabf14fa7904958bca9a5faf8bf9adb8f5c4b54ae2707cb603dbff3546" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
    <div class="col-span-3">
      <h3 class="font-bold text-3xl mb-1">Statistics</h3>
      <ul>
        <li class="mb-2">
          <strong class="text-lg">5x Click-Through Rate</strong>
          <br />
          Personalized videos achieve a 5x CTR compared to generic videos.
        </li>
        <li class="mb-2">
          <strong class="text-lg">23% Increase in CTA Engagement</strong>
          <br />
          23% more viewers click on calls-to-action in personalized videos.
        </li>
        <li class="mb-2">
          <strong class="text-lg"> 2x Conversion Rate</strong>
          <br />
          Personalized videos double the conversion rate compared to generic videos.
        </li>
      </ul>
    </div>
  </section>
</template>

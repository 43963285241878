<script setup></script>

<template>
  <button
    :to="to"
    class="text-xs uppercase tracking-wide lead text-white rounded-full px-3 py-2 cursor-pointer bg-gradient-to-r from-sd-bg from from-50% bg-[length:200%_100%] via-50% bg-left via-sd-blue to-sd-light-blue hover:bg-right transition-all duration-200 inline-flex flex-row items-center gap-1"
  >
    <slot />
  </button>
</template>

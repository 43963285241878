<script setup>
import { ref } from 'vue'
import ArrowRightIcon from '@/components/icons/ArrowRightIcon.vue'

const videoPlayer = ref(null)
</script>

<template>
  <section class="grid grid-cols-3 sm:grid-cols-6 gap-10 mt-10">
    <div class="col-span-3 flex flex-col gap-4 justify-center">
      <h3 class="font-bold text-3xl">Augmented Reality Solutions</h3>
      <p class="mb-4">
        We unleashed the magic of augmented reality to demonstrate the potential of leveraging technology to enhance the
        <strong>in-store customer experience</strong>. By tapping into technologies like Listnr, push notifications, and retail in-store apps, we showcased our ability to meet customers wherever they are. This blend of <strong>innovation and creativity</strong> allowed us to craft a seamless, holistic experience that bridges the gap between brick-and-mortar locations and digital channels.
      </p>
      <div class="bg-gradient-to-r from-sd-bg from from-50% bg-[length:200%_100%] via-50% bg-left via-sd-blue to-sd-light-blue hover:bg-right transition-all duration-200 w-fit bg-clip-text text-lg">
        <p @click="videoPlayer.play()" class="text-transparent font-bold cursor-pointer inline-flex items-center gap-2">Play Example <ArrowRightIcon class="text-sd-bg" /></p>
      </div>
    </div>
    <div class="col-span-3 mb-2">
      <video ref="videoPlayer" controls>
        <source src="https://player.vimeo.com/progressive_redirect/playback/989530127/rendition/1440p/file.mp4?loc=external&log_user=0&signature=3ef8723726047169cd43673dd6b66cc4d08ecf92d4f402af84cd877c8939c09c" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>

    <div class="col-span-3 lg:col-span-2">
      <h3 class="font-bold text-2xl">In-Store Experience</h3>
      <p class="mb-4">
        Augmented reality provides an
        <strong>interactive and immersive</strong> showcase of the customer journey to shareholders, clients and customers.
      </p>
    </div>
    <div class="col-span-3 lg:col-span-2">
      <h3 class="font-bold text-2xl">Leveraging Technology</h3>
      <p class="mb-4">Utilizing <strong>Listnr</strong>, <strong>push notifications</strong>, and <strong>retail in-store apps</strong> ensures we meet customers at various touchpoints, enhancing their overall experience.</p>
    </div>
    <div class="col-span-3 lg:col-span-2">
      <h3 class="font-bold text-2xl">Innovation and Creativity</h3>
      <p class="mb-4">
        Our innovative approach bridges the gap between brick-and-mortar and digital channels, creating a
        <strong>seamless and engaging</strong> customer journey.
      </p>
    </div>
  </section>
</template>

<script setup>
import { onMounted } from 'vue'

onMounted(() => {
  document.querySelector('video').playbackRate = 2
})
</script>

<template>
  <section class="grid grid-cols-3 lg:grid-cols-6 gap-10 mt-10">
    <div class="col-span-3 flex flex-col gap-4 justify-center">
      <h3 class="font-bold text-3xl mb-4">Tailored Solutions for Your Unique Challenges</h3>
      <h3 class="font-bold text-2xl">Uncovering Pain Points</h3>
      <p class="mb-4">Our expertise lies in identifying the inefficiencies and frustrations in your current processes and existing solutions. We complete the research necessary to understand your unique challenges and develop tailored solutions that address these pain points effectively.</p>
      <h3 class="font-bold text-2xl">Custom Solutions</h3>
      <p class="mb-4">We don’t believe in a one-size-fits-all solution to unique problems. Our team excels at creating custom web applications designed to meet your specific needs. Whether it’s streamlining workflows, reporting data intuitively, or mitigating errors in business processes, our solutions are built to solve your unique problems.</p>
    </div>
    <div class="col-span-3 mb-2 min-h-[600px]">
      <iframe src="https://survey.app.stephensdirect.com/app/oprbb1glz4ixoowx" frameborder="0" width="100%" height="100%"></iframe>
    </div>
    <div class="col-span-3">
      <video controls>
        <source src="https://player.vimeo.com/progressive_redirect/playback/859118290/rendition/2160p/file.mp4?loc=external&log_user=0&signature=07ad813ef23f01058c2be63aaacc4ff776a4622e0a90839deeefebe49ae72da4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
    <div class="col-span-3">
      <video controls>
        <source src="https://player.vimeo.com/progressive_redirect/playback/859163046/rendition/2160p/file.mp4?loc=external&log_user=0&signature=bd2b748c762043e3d68f75cd390e361f08c1af75ed5b5896eadfe12734edc1ba" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
    <div class="col-span-3 lg:col-span-2">
      <h3 class="font-bold text-2xl">Secure Backend</h3>
      <p class="mb-4">Security is at the heart of our development process. We build robust and secure backends to ensure your data and operations are always protected, providing you with comprehensive peace of mind.</p>
    </div>
    <div class="col-span-3 lg:col-span-2">
      <h3 class="font-bold text-2xl">Intuitive UI/UX</h3>
      <p class="mb-4">User experience is crucial. Our talented designers create layouts that are both appealing and user-friendly, ensuring your application is easy to navigate and simple for all users to interact with.</p>
    </div>
    <div class="col-span-3 lg:col-span-2">
      <h3 class="font-bold text-2xl">Versatile Development</h3>
      <p class="mb-4">No matter the scope or complexity of your requirements, we have the expertise to meet them. Our development process is flexible and scalable, capable of handling everything from simple tools to sophisticated platforms.</p>
    </div>
    <div class="col-span-4 flex flex-col gap-4 justify-center">
      <h3 class="font-bold text-2xl">Gamification in Web Development</h3>
      <p class="mb-4">Incorporating gamification elements into web development and applications allows us to create engaging and interactive user experiences. By integrating features such as challenges, rewards, and progress trackers, we can motivate users to interact more deeply with our content. This strategy not only enhances user engagement but also fosters a sense of achievement and competition.</p>
      <p class="mb-4">Gamification techniques like badges, points, and leaderboards tap into users’ intrinsic motivations, encouraging them to explore and participate actively. Transforming mundane tasks into interactive challenges and providing real-time feedback through dynamic progress bars and icons can significantly boost user satisfaction and loyalty. This approach turns routine interactions into enjoyable experiences.</p>
    </div>
    <div class="col-span-2 mb-2">
      <img src="/game.png" alt="" class="mb-4" />
      <img src="/gamification_nsm_setpay_trivia.gif" alt="" />
    </div>
  </section>
</template>

<script setup>
import StaticBanner from '@/components/StaticBanner.vue'
import AnimatedBanner from '@/components/AnimatedBanner.vue'
import ToggleSwitch from '@/components/ToggleSwitch.vue'
import StyledButton from '@/components/StyledButton.vue'
import ArrowRightIcon from '@/components/icons/ArrowRightIcon.vue'
import { ref } from 'vue'

const showAnimated = ref(false)
const restartFunction = ref(null)
const videoPlayer = ref(null)

const handleReplay = () => {
  if (showAnimated.value && restartFunction.value) {
    restartFunction.value()
  }
}

const setRestartFunction = (fn) => {
  restartFunction.value = fn
}
</script>

<template>
  <section class="grid grid-cols-3 sm:grid-cols-6 gap-10 mt-10">
    <div class="col-span-3 lg:col-span-6 flex flex-col lg:flex-row items-center gap-10">
      <div class="w-[300px]">
        <AnimatedBanner v-if="showAnimated" :restart="setRestartFunction" />
        <StaticBanner v-else />
        <div class="flex flex-row justify-between mt-4">
          <ToggleSwitch v-model="showAnimated" />
          <StyledButton @click="handleReplay" v-if="showAnimated">Restart</StyledButton>
        </div>
      </div>
      <div class="flex-1">
        <h3 class="font-bold text-2xl">Easy Integration of Animated & Dynamic Elements</h3>
        <p class="mb-4">Integrating elements such as <strong>buttons</strong>, <strong>maps</strong>, <strong>timers</strong>, <strong>images</strong>, and <strong>animated objects</strong> with HTML5 is simple, enhancing the interactivity and visual appeal of your content.</p>
        <h3 class="font-bold text-2xl">Dynamic Content</h3>
        <p class="mb-4">The display and animation of the banner can vary based on specific user data. For example, the ad can <strong>display weather warnings</strong> or preparation tips based on the <strong>user’s location</strong> and specific weather conditions.</p>
        <div class="bg-gradient-to-r from-sd-bg from from-50% bg-[length:200%_100%] via-50% bg-left via-sd-blue to-sd-light-blue hover:bg-right transition-all duration-200 w-fit bg-clip-text text-lg">
          <a href="https://qc.stephensdirect.com/banners/24-SD00-00377_HTML_5_Banner_Animation" target="_blank" class="text-transparent font-bold cursor-pointer inline-flex items-center gap-2"> More Examples <ArrowRightIcon class="text-sd-bg" /> </a>
        </div>
      </div>
    </div>
    <div class="col-span-3 lg:col-span-2">
      <h3 class="font-bold text-2xl">Enhanced Advertising</h3>
      <p class="mb-4">Multiple links can be placed in a single ad, creating more comprehensive and interactive advertising experiences. Text remains crisp and legible, unlike smaller text on GIFs which tends to become pixelated and less readable.</p>
    </div>
    <div class="col-span-3 lg:col-span-2">
      <h3 class="font-bold text-2xl">Better Quality</h3>
      <p class="mb-4">This method offers better quality and smaller file sizes compared to GIFs. It also includes an unlimited range of colors with no decay in quality, unlike GIFs which lower in quality with a larger range of colors.</p>
    </div>
    <div class="col-span-3 lg:col-span-2">
      <h3 class="font-bold text-2xl">Versatility</h3>
      <p class="mb-4">Developers can make quick edits to update content within the code, such as updating disclaimers or images if the banner is being reused later on, without the need for photo software or animation programs.</p>
    </div>
    <div class="col-span-3 gap-4 my-auto">
      <h3 class="font-bold text-2xl">Banner Generator App</h3>
      <p class="mb-4">Embedding HTML banners into a website can be challenging for business owners. This intuitive, self-service banner generator only requires partner identification to choose from several available banner styles, promotions, and terms. The cost-effective solution offers a simple interface to generate HTML banners tailored to your business. Copy and paste posthaste!</p>
    </div>
    <div class="col-span-3 mb-2">
      <img src="/app_sycc_banners.gif" alt="" />
    </div>
    <div class="col-span-3 lg:col-span-4 gap-4 my-auto">
      <h3 class="font-bold text-2xl">Adaptability</h3>
      <p class="mb-4">Content adapts perfectly on both mobile and desktop devices, ensuring a consistent user experience across all platforms.</p>
      <h3 class="font-bold text-2xl">Higher Click-Through Rate</h3>
      <p class="mb-4">These dynamic and interactive elements contribute to a higher click-through rate, making your ads more effective.</p>
      <video class="max-w-96" controls>
        <source src="https://player.vimeo.com/progressive_redirect/playback/989530401/rendition/2160p/file.mp4?loc=external&log_user=0&signature=a2058f203d5046d8eb95d8094513085b208c482f03fa8572882a3d4935ae186f" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
    <div class="col-span-3 lg:col-span-2 mb-2">
      <video ref="videoPlayer" controls>
        <source src="https://player.vimeo.com/progressive_redirect/playback/989530380/rendition/2160p/file.mp4?loc=external&log_user=0&signature=e3c55b427c0e46499e6841a496d71e49e8f7648958c14c7ceb6b57c1cf0ff071" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  </section>
</template>

<style>
* {
  box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.wrapper {
  padding: 1em;
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  font-family: 'Montserrat', sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.wrapper > div {
  padding: 0 2em;
}

.wrapper > div:not(:last-child) {
  border-right: 1px solid #bbb;
}

.banner {
  --w: 300px;
  --h: 250px;
  width: var(--w);
  height: var(--h);
  background-color: #3b3c43;
  overflow: hidden;
  position: relative;
}

.static {
  background-image: url(/public/handCard.png);
  background-size: 85%;
  background-position: right bottom;
  background-repeat: no-repeat;
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: min-content min-content 1fr;
  padding: 1.5em;
}

.static > * {
  margin: 3px 0;
}

.static .applyNowBtnWrap {
  display: flex;
  align-items: flex-end;
}

.static .applyNowBtn {
  width: fit-content;
  border: 2px solid #fff;
  padding: 7px 15px;
  border-radius: 1000em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/* Animated */

.gradient {
  position: absolute;
  width: 120%;
  aspect-ratio: 1 / 1;
  background: rgb(83, 84, 92);
  background: -moz-radial-gradient(circle, rgba(83, 84, 92, 1) 0%, rgba(59, 60, 67, 1) 70%);
  background: -webkit-radial-gradient(circle, rgba(83, 84, 92, 1) 0%, rgba(59, 60, 67, 1) 70%);
  background: radial-gradient(circle, rgba(83, 84, 92, 1) 0%, rgba(59, 60, 67, 1) 70%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#53545c",endColorstr="#3b3c43",GradientType=1);
}

.animate > *:not(:first-child) {
  position: relative;
}

.animateBg {
  width: 300px;
  height: 250px;
  background-image: url(/public/handCard.png);
  background-size: 85%;
  background-position-x: 300px;
  background-position-y: bottom;
  background-repeat: no-repeat;
}

.animatePadding {
  width: 100%;
  height: 100%;
  padding: 1.5em 1.5em 0.5em 1.5em;
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: max-content 1fr;
}

.animate .applyNowBtnWrap {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;
  row-gap: 5px;
}

.animate .applyNowBtn {
  width: fit-content;
  border: 2px solid #fff;
  padding: 7px 15px;
  border-radius: 1000em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  background-color: transparent;
}

.applyNowBtnWrap .disclaimer {
  color: #fff;
  font-weight: 400;
  font-size: 8px;
  text-decoration: underline;
  cursor: pointer;
  opacity: 0;
  padding: 0 29px;
}

.applyNowBtnWrap .disclaimer a {
  color: #fff !important;
}

.animateButtons {
  margin: 15px 0 0 0;
}

.animateButtons > * {
  border: none;
  padding: 7px 14px;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

* {
  box-sizing: border-box !important;
  margin: 0;
  padding: 0;
}

.wrapper-animated {
  width: 300px;
  height: 250px;
  overflow: hidden;
  background-image: url(/public/BG-300x250.jpg);
  background-size: contain;
  position: relative;
}

.couch {
  width: 149px;
  height: 117px;
  background: url(/public/css_sprites.png) -10px -135px;
  position: absolute;
  bottom: 25px;
  /* right: 0; */
  right: -149px;
}

.lamp {
  width: 63px;
  height: 75px;
  background: url(/public/css_sprites.png) -371px -10px;
  position: absolute;
  /* top: 0; */
  right: 15px;
  top: -75px;
}

.PB {
  width: 156px;
  height: 28px;
  background: url(/public/css_sprites.png) -10px -272px;
  position: absolute;
  top: 0;
  left: -156px;
}

.spacer {
  height: 30px;
}

.floorPlant {
  width: 81px;
  height: 172px;
  background: url(/public/css_sprites.png) -196px -10px;
  position: absolute;
  top: 45px;
  /* left: 110px; */
  left: -81px;
}

.PBcard {
  width: 166px;
  height: 105px;
  background: url(/public/css_sprites.png) -10px -10px;
}

.table {
  width: 54px;
  height: 106px;
  background: url(/public/css_sprites.png) -297px -10px;
  position: absolute;
  right: 45px;
  /* bottom: 15px; */
  bottom: -106px;
}

.wrapper-animated > div {
  height: 100%;
  width: 100%;
  position: absolute;
  overflow: hidden;
}

.frame1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper-animated > div:not(:first-child, .PBwrap, .furnishings) > * {
  opacity: 0;
}

/* frame CSS details */

.f1ContentWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 5px;
}

.f2ContentWrap,
.f3ContentWrap,
.f4ContentWrap {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.f2Copy {
  margin: 0 0 0 10px;
}

.overlay {
  background-color: #404040;
  width: 100%;
  height: 100%;
  /* opacity: 0.6; */
  position: absolute;
}

.f5ContentWrap {
  width: 100%;
  height: 100%;
  position: absolute;
  display: grid;
  grid-template-rows: min-content min-content auto;
  align-items: center;
  justify-items: center;
  color: #ffffff;
  row-gap: 10px;
  padding: 20px 0 10px 0;
}
.f5ContentWrap > * {
  position: relative;
}

.buttonDisclaimer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  row-gap: 3px;
  color: #fff;
  font-family: Arial;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.buttonDisclaimer a {
  color: #ffffff !important;
  text-decoration-line: underline;
}
</style>

<script setup>
import { onMounted } from 'vue'

onMounted(() => {
  document.querySelector('video').playbackRate = 2
})
</script>

<template>
  <section class="grid grid-cols-2 sm:grid-cols-6 gap-10 mt-10">
    <div class="col-span-3 flex flex-col gap-3">
      <img src="/mi_4.png" alt="" />
      <h3 class="font-bold text-3xl">Yearly Rewards Summary</h3>
      <p class="mb-4">
        Powered by data, a comprehensive overview of earned rewards can
        <strong>enhance customer relationships</strong>, <strong>improve satisfaction</strong>, and <strong>drive future sales</strong> by directly showing the accumulative benefits.
      </p>
    </div>
    <div class="col-span-3 flex flex-col gap-3">
      <img src="/mi_3.png" alt="" />
      <h3 class="font-bold text-3xl">Local Weather</h3>
      <p class="mb-4">Incorporating local weather information can make content more engaging, useful, and relevant, leading to better customer relationships and improved marketing performance.</p>
    </div>
    <div class="col-span-3 flex flex-col gap-4 justify-center">
      <video controls>
        <source src="https://player.vimeo.com/progressive_redirect/playback/989556480/rendition/720p/file.mp4?loc=external&log_user=0&signature=c14550d10be65cc854b11546245177763e99600b380f629063dba6b83ab7fbf2" type="video/mp4" />
      </video>
    </div>
    <div class="col-span-3 flex flex-col gap-4 justify-center">
      <h3 class="font-bold text-3xl">Rewards Progress Tracker</h3>
      <p class="mb-4 lg:mb-0">Rewards Progress Trackers provide users with real-time updates on their journey towards earning their next reward. The animated progress bar dynamically illustrates their proximity to the next free item, enhancing engagement and motivation. Additionally, the dynamic icon displays the number of free items already earned, fostering a sense of achievement and encouraging continued participation.</p>
    </div>
    <div class="col-span-3">
      <img src="/cover_images/mi_cover.png" alt="" />
    </div>
    <div class="col-span-3 flex flex-col gap-4 justify-center">
      <h3 class="font-bold text-3xl">Rewards Chart Tracker</h3>
      <p class="mb-4">Rewards Chart Trackers allow us the ability to provide users with real-time updates on their progress toward earning their next reward. This feature not only engages users but also motivates them to continue accumulating points.</p>
    </div>
    <div class="col-span-3">
      <img src="/mi_2.png" alt="" />
    </div>
    <div class="col-span-3 flex flex-col gap-4 justify-center">
      <h3 class="font-bold text-3xl">Featured Product Grid Templates</h3>
      <p class="mb-4">
        We develop automated product grid templates that dynamically display
        <strong>product images</strong>, <strong>descriptions</strong>, <strong>prices</strong>, and <strong>potential savings</strong> or points earned through credit card usage. This streamlined presentation simplifies the shopping experience and highlights essential product information.
      </p>
    </div>
    <div class="col-span-6">
      <img src="/lowes.png" alt="" class="shadow-lg rounded-md" />
    </div>
    <div class="col-span-3">
      <h3 class="font-bold text-2xl">Tailored Navigation</h3>
      <p class="mb-4">We can help you implement <strong>personalized category navigation</strong> that adapts based on user website visits and interactions with specific categories. This bespoke navigation system enhances user experience by showcasing the most relevant content.</p>
    </div>
    <div class="col-span-3">
      <h3 class="font-bold text-2xl">Interactive In-App Messages</h3>
      <p class="mb-4">
        We can leverage the combination of
        <strong>Airship and Movable Ink</strong> technologies to deliver rich, dynamic content within the app. Additionally, we can utilize<strong> personalized in-app video messages</strong>
        (Idomoo) to create engaging and tailored user interactions.
      </p>
    </div>
  </section>
</template>

<script setup></script>

<template>
  <div class="staticWrap">
    <div class="banner static">
      <!-- Sync Financial -->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="152"
        height="14"
        viewBox="0 0 152 14"
        fill="none"
      >
        <path d="M151.746 11H150.637V0.667542H151.746V11Z" fill="white" />
        <path
          d="M147.135 3.99495V11H146.04V9.99303H145.982C145.646 10.5184 144.975 11.1459 143.705 11.1459C141.735 11.1459 140.378 9.64277 140.378 7.49747C140.378 5.35217 141.735 3.84901 143.705 3.84901C144.975 3.84901 145.646 4.47654 145.982 5.00192H146.04V3.99495H147.135ZM146.011 8.8547V6.14024C145.719 5.42514 144.989 4.8268 143.924 4.8268C142.523 4.8268 141.545 5.86296 141.545 7.49747C141.545 9.13199 142.523 10.1682 143.924 10.1682C144.989 10.1682 145.719 9.5698 146.011 8.8547Z"
          fill="white"
        />
        <path
          d="M137.319 3.99494V11H136.21V3.99494H137.319ZM135.991 1.89343C135.991 1.4702 136.327 1.13454 136.764 1.13454C137.202 1.13454 137.538 1.4702 137.538 1.89343C137.538 2.31665 137.202 2.65231 136.764 2.65231C136.327 2.65231 135.991 2.31665 135.991 1.89343Z"
          fill="white"
        />
        <path
          d="M129.951 11.1459C127.952 11.1459 126.463 9.65737 126.463 7.49747C126.463 5.33758 127.952 3.84901 129.951 3.84901C131.834 3.84901 133.001 5.16246 133.074 6.35915H131.921C131.805 5.62946 131.148 4.79761 129.966 4.79761C128.638 4.79761 127.631 5.86296 127.631 7.49747C127.631 9.13199 128.638 10.1973 129.966 10.1973C131.148 10.1973 131.805 9.36549 131.921 8.6358H133.074C133.001 9.83249 131.834 11.1459 129.951 11.1459Z"
          fill="white"
        />
        <path
          d="M117.45 11V3.99495H118.53V5.00192H118.588C118.953 4.41817 119.624 3.84901 120.879 3.84901C122.514 3.84901 123.433 4.8122 123.433 6.76778V11H122.324V6.85534C122.324 5.49811 121.784 4.84139 120.661 4.84139C119.551 4.84139 118.851 5.46893 118.559 6.14024V11H117.45Z"
          fill="white"
        />
        <path
          d="M113.947 3.99495V11H112.853V9.99303H112.794C112.459 10.5184 111.787 11.1459 110.518 11.1459C108.548 11.1459 107.19 9.64277 107.19 7.49747C107.19 5.35217 108.548 3.84901 110.518 3.84901C111.787 3.84901 112.459 4.47654 112.794 5.00192H112.853V3.99495H113.947ZM112.824 8.8547V6.14024C112.532 5.42514 111.802 4.8268 110.737 4.8268C109.336 4.8268 108.358 5.86296 108.358 7.49747C108.358 9.13199 109.336 10.1682 110.737 10.1682C111.802 10.1682 112.532 9.5698 112.824 8.8547Z"
          fill="white"
        />
        <path
          d="M98.1769 11V3.99495H99.2568V5.00192H99.3152C99.68 4.41817 100.351 3.84901 101.606 3.84901C103.241 3.84901 104.16 4.8122 104.16 6.76778V11H103.051V6.85534C103.051 5.49811 102.511 4.84139 101.388 4.84139C100.278 4.84139 99.5779 5.46893 99.286 6.14024V11H98.1769Z"
          fill="white"
        />
        <path
          d="M94.6804 3.99494V11H93.5713V3.99494H94.6804ZM93.3524 1.89343C93.3524 1.4702 93.688 1.13454 94.1258 1.13454C94.5636 1.13454 94.8993 1.4702 94.8993 1.89343C94.8993 2.31665 94.5636 2.65231 94.1258 2.65231C93.688 2.65231 93.3524 2.31665 93.3524 1.89343Z"
          fill="white"
        />
        <path
          d="M85.4203 11V1.28049H90.5136V2.31665H86.5879V5.51271H90.2217V6.54887H86.5879V11H85.4203Z"
          fill="white"
        />
        <path
          d="M71.4685 3.99495H72.6652L74.7375 9.61359H74.7959L76.8536 3.99495H78.0212L74.1976 13.8458H73.0009L74.1684 10.9708L71.4685 3.99495Z"
          fill="white"
        />
        <path
          d="M62.8637 11V3.99495H63.9437V5.00192H64.002C64.3669 4.41817 65.0382 3.84901 66.2933 3.84901C67.9278 3.84901 68.8472 4.8122 68.8472 6.76778V11H67.7381V6.85534C67.7381 5.49811 67.1981 4.84139 66.0744 4.84139C64.9652 4.84139 64.2647 5.46893 63.9728 6.14024V11H62.8637Z"
          fill="white"
        />
        <path
          d="M56.2502 11.1459C54.1924 11.1459 52.7039 9.70115 52.7039 7.49747C52.7039 5.2938 54.1924 3.84901 56.2502 3.84901C58.3079 3.84901 59.7965 5.2938 59.7965 7.49747C59.7965 9.70115 58.3079 11.1459 56.2502 11.1459ZM56.2502 10.1973C57.6512 10.1973 58.629 9.16118 58.629 7.49747C58.629 5.83377 57.6512 4.79761 56.2502 4.79761C54.8492 4.79761 53.8714 5.83377 53.8714 7.49747C53.8714 9.16118 54.8492 10.1973 56.2502 10.1973Z"
          fill="white"
        />
        <path
          d="M50.6867 3.89279V4.97274H50.2927C49.1981 4.97274 48.4976 5.57109 48.2058 6.44672V11H47.0966V3.99495H48.1766V5.16246H48.2349C48.556 4.41817 49.3003 3.89279 50.2489 3.89279H50.6867Z"
          fill="white"
        />
        <path
          d="M37.6454 11V0.667542H38.7545V4.97273H38.8129C39.1777 4.40357 39.8345 3.84901 41.0749 3.84901C42.7095 3.84901 43.6289 4.8122 43.6289 6.76778V11H42.5197V6.85534C42.5197 5.49811 41.9798 4.84139 40.856 4.84139C39.7469 4.84139 39.0464 5.46893 38.7545 6.14024V11H37.6454Z"
          fill="white"
        />
        <path
          d="M31.3868 11.1459C29.3874 11.1459 27.8988 9.65737 27.8988 7.49747C27.8988 5.33758 29.3874 3.84901 31.3868 3.84901C33.2694 3.84901 34.4369 5.16246 34.5099 6.35915H33.3569C33.2402 5.62946 32.5835 4.79761 31.4014 4.79761C30.0733 4.79761 29.0663 5.86296 29.0663 7.49747C29.0663 9.13199 30.0733 10.1973 31.4014 10.1973C32.5835 10.1973 33.2402 9.36549 33.3569 8.6358H34.5099C34.4369 9.83249 33.2694 11.1459 31.3868 11.1459Z"
          fill="white"
        />
        <path
          d="M18.8854 11V3.99495H19.9654V5.00192H20.0237C20.3886 4.41817 21.0599 3.84901 22.315 3.84901C23.9495 3.84901 24.8689 4.8122 24.8689 6.76778V11H23.7598V6.85534C23.7598 5.49811 23.2198 4.84139 22.0961 4.84139C20.9869 4.84139 20.2864 5.46893 19.9945 6.14024V11H18.8854Z"
          fill="white"
        />
        <path
          d="M9.67084 3.99495H10.8675L12.9399 9.61359H12.9982L15.056 3.99495H16.2235L12.3999 13.8458H11.2032L12.3707 10.9708L9.67084 3.99495Z"
          fill="white"
        />
        <path
          d="M4.13008 11.1459C2.08694 11.1459 0.802677 9.92006 0.656738 8.16879H1.85344C2.01397 9.30711 2.69988 10.139 4.13008 10.139C5.23921 10.139 6.28997 9.64277 6.28997 8.43148C6.28997 7.4245 5.57487 6.98669 4.74302 6.76778L3.28364 6.38834C1.92641 6.03809 0.96321 5.2938 0.96321 3.80522C0.96321 2.03937 2.33503 1.13454 4.11549 1.13454C6.17322 1.13454 7.25317 2.34584 7.34073 3.80522H6.14403C6.05647 2.84203 5.29759 2.14152 4.04252 2.14152C2.94798 2.14152 2.13072 2.6669 2.13072 3.71766C2.13072 4.73923 2.90419 5.16245 3.67767 5.36677L5.13706 5.74621C6.45051 6.08187 7.45748 6.81156 7.45748 8.32932C7.45748 10.2265 5.91053 11.1459 4.13008 11.1459Z"
          fill="white"
        />
      </svg>

      <!-- Partner Brand -->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="244"
        height="28"
        viewBox="0 0 244 28"
        fill="none"
      >
        <path
          d="M238.611 24.3425H238.464C237.578 25.7451 235.843 27.4429 232.411 27.4429C227.169 27.4429 223.774 23.5674 223.774 17.9201C223.774 12.2729 227.169 8.39736 232.411 8.39736C235.585 8.39736 237.172 9.87376 238.021 11.0549H238.168V0.867722H243.557V27H238.611V24.3425ZM238.168 20.7622V15.0781C237.246 13.5648 235.806 12.8266 234.035 12.8266C231.119 12.8266 229.31 14.8566 229.31 17.9201C229.31 20.9837 231.119 23.0137 234.035 23.0137C235.806 23.0137 237.246 22.2755 238.168 20.7622Z"
          fill="#F7C837"
        />
        <path
          d="M202.43 27V8.84028H207.376V11.6085H207.524C208.52 9.98449 210.329 8.39736 213.725 8.39736C217.637 8.39736 220.59 10.5012 220.59 15.4472V27H215.201V16.4068C215.201 13.86 213.835 12.8635 211.842 12.8635C210.07 12.8635 208.631 13.6755 207.819 15.1888V27H202.43Z"
          fill="#F7C837"
        />
        <path
          d="M197.708 8.84028V27H192.762V24.3425H192.615C191.729 25.7451 189.994 27.4429 186.561 27.4429C181.32 27.4429 177.924 23.5674 177.924 17.9201C177.924 12.2729 181.32 8.39736 186.561 8.39736C189.994 8.39736 191.729 10.0952 192.615 11.4978H192.762V8.84028H197.708ZM192.319 20.7622V15.0781C191.397 13.5648 189.957 12.8266 188.185 12.8266C185.27 12.8266 183.461 14.8566 183.461 17.9201C183.461 20.9837 185.27 23.0137 188.185 23.0137C189.957 23.0137 191.397 22.2755 192.319 20.7622Z"
          fill="#F7C837"
        />
        <path
          d="M176.753 8.58191V13.6017H175.756C172.951 13.6017 171.142 14.6721 170.404 16.3699V27H165.015V8.84028H169.961V12.4575H170.109C170.995 10.2059 172.988 8.58191 175.941 8.58191H176.753Z"
          fill="#F7C837"
        />
        <path
          d="M147.885 16.739V22.4232H152.388C154.75 22.4232 155.857 21.3897 155.857 19.5811C155.857 17.7725 154.75 16.739 152.388 16.739H147.885ZM147.885 6.99478V12.3836H151.908C154.123 12.3836 155.082 11.424 155.082 9.68921C155.082 7.95444 154.123 6.99478 151.908 6.99478H147.885ZM142.348 27V2.41794H151.945C157.629 2.41794 160.766 4.78018 160.766 8.72955C160.766 11.7931 158.884 13.5278 157.334 14.1553V14.303C159.585 15.115 161.542 16.5914 161.542 19.9133C161.542 24.4163 158.035 27 151.465 27H142.348Z"
          fill="#F7C837"
        />
        <path
          d="M130.94 8.58191V13.6017H129.943C127.138 13.6017 125.329 14.6721 124.591 16.3699V27H119.202V8.84028H124.148V12.4575H124.296C125.182 10.2059 127.175 8.58191 130.128 8.58191H130.94Z"
          fill="#F7C837"
        />
        <path
          d="M106.11 27.4429C99.651 27.4429 95.8862 23.4566 95.8862 17.9571C95.8862 12.3467 99.7986 8.39736 105.815 8.39736C111.942 8.39736 115.818 12.5313 115.818 18.2154V19.1751H101.57C101.792 21.9064 103.637 23.1983 106.073 23.1983C107.956 23.1983 109.469 22.4232 109.838 21.3528H115.338C115.079 24.5639 111.61 27.4429 106.11 27.4429ZM105.815 12.4206C103.674 12.4206 102.087 13.7862 101.681 16.0008H109.949C109.543 13.7862 107.956 12.4206 105.815 12.4206Z"
          fill="#F7C837"
        />
        <path
          d="M74.5427 27V8.84028H79.4887V11.6085H79.6363C80.6329 9.98449 82.4415 8.39736 85.8372 8.39736C89.7496 8.39736 92.7024 10.5012 92.7024 15.4472V27H87.3136V16.4068C87.3136 13.86 85.9479 12.8635 83.9548 12.8635C82.1831 12.8635 80.7436 13.6755 79.9316 15.1888V27H74.5427Z"
          fill="#F7C837"
        />
        <path
          d="M61.3385 21.1313V13.048H58.0535V12.4944L59.2346 8.84028H61.3385V3.93125H66.7273V8.84028H71.3042V13.048H66.7273V20.1717C66.7273 21.9802 67.3917 22.5708 69.0527 22.5708H71.3042V27H68.4621C63.6638 27 61.3385 25.2652 61.3385 21.1313Z"
          fill="#F7C837"
        />
        <path
          d="M57.0837 8.58191V13.6017H56.0871C53.282 13.6017 51.4734 14.6721 50.7352 16.3699V27H45.3463V8.84028H50.2923V12.4575H50.4399C51.3257 10.2059 53.3189 8.58191 56.2717 8.58191H57.0837Z"
          fill="#F7C837"
        />
        <path
          d="M40.6244 8.84028V27H35.6785V24.3425H35.5308C34.645 25.7451 32.9102 27.4429 29.4776 27.4429C24.2364 27.4429 20.8407 23.5674 20.8407 17.9201C20.8407 12.2729 24.2364 8.39736 29.4776 8.39736C32.9102 8.39736 34.645 10.0952 35.5308 11.4978H35.6785V8.84028H40.6244ZM35.2356 20.7622V15.0781C34.3128 13.5648 32.8733 12.8266 31.1016 12.8266C28.1858 12.8266 26.3772 14.8566 26.3772 17.9201C26.3772 20.9837 28.1858 23.0137 31.1016 23.0137C32.8733 23.0137 34.3128 22.2755 35.2356 20.7622Z"
          fill="#F7C837"
        />
        <path
          d="M6.15712 7.25315V14.1922H9.88503C12.2473 14.1922 13.6129 12.8635 13.6129 10.7227C13.6129 8.58191 12.2473 7.25315 9.88503 7.25315H6.15712ZM6.15712 19.0274V27H0.58371V2.41794H9.66357C16.0121 2.41794 19.2971 5.81366 19.2971 10.7227C19.2971 15.6317 16.0121 19.0274 9.66357 19.0274H6.15712Z"
          fill="#F7C837"
        />
      </svg>

      <!-- Apply Now -->
      <div class="applyNowBtnWrap">
        <a
          href="https://www.synchrony.com/"
          target="_blank"
          style="color: #000000"
        >
          <div class="applyNowBtn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="73"
              height="13"
              viewBox="0 0 73 13"
              fill="none"
            >
              <path
                d="M66.5966 3.1013H66.9525L68.8824 6.8105H68.9098L70.3058 3.23817H72.3042L69.1288 10.2049H68.7729L66.7335 6.29039H66.7061L64.6668 10.2049H64.3109L61.1355 3.23817H63.2433L64.6394 6.8105H64.6668L66.5966 3.1013Z"
                fill="white"
              />
              <path
                d="M56.9532 10.1365C54.6812 10.1365 53.1208 8.74038 53.1208 6.6052C53.1208 4.47001 54.6812 3.07393 56.9532 3.07393C59.2253 3.07393 60.7856 4.47001 60.7856 6.6052C60.7856 8.74038 59.2253 10.1365 56.9532 10.1365ZM56.9532 8.46664C57.9934 8.46664 58.7052 7.72754 58.7052 6.6052C58.7052 5.48286 57.9934 4.74375 56.9532 4.74375C55.913 4.74375 55.2013 5.48286 55.2013 6.6052C55.2013 7.72754 55.913 8.46664 56.9532 8.46664Z"
                fill="white"
              />
              <path
                d="M43.813 0.623947L49.6985 6.07141H49.7806V0.856627H51.7789V10.0954L51.423 10.2049L45.5376 4.77113H45.4555V9.97223H43.4572V0.733444L43.813 0.623947Z"
                fill="white"
              />
              <path
                d="M31.7042 3.23817H33.8394L35.386 7.83703H35.4134L36.9601 3.23817H39.0542L35.3724 12.6412H33.3056L34.3458 9.97222L31.7042 3.23817Z"
                fill="white"
              />
              <path
                d="M30.6036 9.97222H28.6053V0.281769H30.6036V9.97222Z"
                fill="white"
              />
              <path
                d="M21.8448 4.22364H21.8996C22.2281 3.70353 22.8713 3.07393 24.1442 3.07393C26.0878 3.07393 27.347 4.51107 27.347 6.6052C27.347 8.69932 26.0878 10.1365 24.1442 10.1365C22.9672 10.1365 22.3786 9.58898 22.0638 9.15099H22.0091V12.6412H20.0107V3.23817H21.8448V4.22364ZM22.0091 5.55129V7.6591C22.3512 8.22027 22.885 8.49401 23.542 8.49401C24.6233 8.49401 25.294 7.74122 25.294 6.6052C25.294 5.46917 24.6233 4.71638 23.542 4.71638C22.885 4.71638 22.3512 4.99012 22.0091 5.55129Z"
                fill="white"
              />
              <path
                d="M13.2503 4.22364H13.305C13.6335 3.70353 14.2768 3.07393 15.5497 3.07393C17.4933 3.07393 18.7525 4.51107 18.7525 6.6052C18.7525 8.69932 17.4933 10.1365 15.5497 10.1365C14.3726 10.1365 13.7841 9.58898 13.4693 9.15099H13.4145V12.6412H11.4162V3.23817H13.2503V4.22364ZM13.4145 5.55129V7.6591C13.7567 8.22027 14.2905 8.49401 14.9475 8.49401C16.0287 8.49401 16.6994 7.74122 16.6994 6.6052C16.6994 5.46917 16.0287 4.71638 14.9475 4.71638C14.2905 4.71638 13.7567 4.99012 13.4145 5.55129Z"
                fill="white"
              />
              <path
                d="M10.6712 9.97223H8.39917L7.6327 8.35715H3.88244L3.11596 9.97223H0.9534L5.63438 0.70607H5.99025L10.6712 9.97223ZM5.71651 4.30577L4.62154 6.67364H6.89359L5.79863 4.30577H5.71651Z"
                fill="white"
              />
            </svg>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<template>
  <section class="grid grid-cols-2 sm:grid-cols-6 gap-10 mt-10">
    <div class="col-span-2">
      <img src="/Static_SD_com.svg" alt="Static QR Code Example" />
    </div>
    <div class="col-span-4 flex flex-col gap-4 justify-center">
      <h3 class="font-bold text-3xl">Static QR Codes</h3>
      <p class="mb-4">Static QR codes are convenient and can contain many types of content, such as web addresses, contact information, WiFi credentials, and more.</p>
      <div class="flex flex-row gap-4 items-center">
        <img src="/stephens-bug.png" alt="" class="w-4" />
        <p class="text-xs italic">
          Between 2022 and 2025, the number of consumers in the U.S. using their smartphones to scan QR codes is expected to rise by 16 million. (Source:
          <a href="https://www.statista.com/statistics/1337584/number-of-smartphone-qr-code-scanners-usa/" target="_blank" class="text-sd-blue underline">Statista</a>)
        </p>
      </div>
    </div>
    <div class="col-span-2">
      <img src="/Dynamic_SD_com.svg" alt="Dynamic QR Code Example" />
    </div>
    <div class="col-span-4 flex flex-col gap-4 justify-center">
      <h3 class="font-bold text-3xl">Dynamic QR Codes</h3>
      <p class="mb-4">Dynamic QR codes can be modified after deployment or print, making them useful for time-limited offers or events. They also have the ability to capture basic information like the number of scans per QR code. Note that dynamic QR codes are only available for web addresses.</p>
      <div class="flex flex-row gap-4 items-center">
        <img src="/stephens-bug.png" alt="" class="w-4" />
        <p class="text-xs italic">
          84% of mobile users scanned a QR code at least once. (Source:
          <a href="https://www.businesswire.com/news/home/20200915005151/en/MobileIron-Research-Reveals-QR-Codes-Pose-Significant-Security-Risks-to-Enterprises-and-End-Users" target="_blank" class="text-sd-blue underline">Business Wire</a>)
        </p>
      </div>
    </div>
    <div class="col-span-2">
      <img src="/Styled_QR.jpeg" alt="Styled QR Code Example" />
    </div>
    <div class="col-span-4 flex flex-col gap-4 justify-center">
      <h3 class="font-bold text-3xl">Styled QR Codes</h3>
      <p class="mb-4">Styled QR codes can be customized using color or small graphics. To ensure they are easily scannable, it is important to maintain high contrast between the QR code and its background.</p>
      <div class="flex flex-row gap-4 items-center">
        <img src="/stephens-bug.png" alt="" class="w-4" />
        <p class="text-xs italic">
          Up to 30% of a QR Code can be damaged and the code will still be scannable. (Source:
          <a href="https://www.fastcompany.com/90740485/how-do-qr-codes-work-and-what-makes-them-dangerous" target="_blank" class="text-sd-blue underline">Fast Company</a>)
        </p>
      </div>
    </div>
  </section>
</template>

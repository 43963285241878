<template>
  <section class="grid grid-cols-3 sm:grid-cols-6 gap-10 mt-10">
    <div class="col-span-3 mb-2">
      <img src="/lzb_rfp.png" alt="" />
    </div>
    <div class="col-span-3 mb-2">
      <img src="/toro_rfp.png" alt="" />
    </div>

    <div class="col-span-3 lg:col-span-2">
      <h3 class="font-bold text-2xl">
        Efficient Distribution, Access, and Updates
      </h3>
      <p class="mb-4">
        Digital RFPs can be swiftly distributed via email or a secure online
        platform, providing instant and broad access to potential partners, no
        matter their location. Updates and clarifications can be implemented
        quickly and efficiently, ensuring all participants are working with the
        most current information.
      </p>
    </div>
    <div class="col-span-3 lg:col-span-2">
      <h3 class="font-bold text-2xl">Better Tracking and Analytics</h3>
      <p class="mb-4">
        Digital RFPs can track when and how potential partners engage with the
        document, providing valuable insights into their interest and areas of
        focus.
      </p>
    </div>
    <div class="col-span-3 lg:col-span-2">
      <h3 class="font-bold text-2xl">Enhanced Engagement</h3>
      <p class="mb-4">
        Interactive elements, such as videos and infographics, make the RFP more
        engaging and easier to understand, encouraging more thoughtful
        responses. The inclusion of intuitive navigation helps users quickly
        find relevant information, streamlining the experience.
      </p>
    </div>
  </section>
</template>

import HomePage from '@/components/pages/HomePage.vue'
import NotFoundPage from '@/components/pages/NotFoundPage.vue'
import DigitalSignagePage from '@/components/pages/analog_experiences/DigitalSignagePage.vue'
import QrCodesPage from '@/components/pages/analog_experiences/QrCodesPage.vue'
import EOPPresentationsPage from '@/components/pages/business_development/EOPPresentationsPage.vue'
import BDPresentationsPage from '@/components/pages/business_development/BDPresentationsPage.vue'
import RfpsPage from '@/components/pages/business_development/RfpsPage.vue'
import AugmentedRealityPage from '@/components/pages/digital_experiences/AugmentedRealityPage.vue'
import ThreeDEnvironmental from '@/components/pages/digital_experiences/3DEnvironmental.vue'
import CreditCalculatorPage from '@/components/pages/web_development/CreditCalculatorPage.vue'
import LandingPagesPage from '@/components/pages/web_development/LandingPagesPage.vue'
import MovableInkPage from '@/components/pages/web_development/MovableInkPage.vue'
import PersonalizedVideoPage from '@/components/pages/web_development/PersonalizedVideoPage.vue'
import WebApplicationsPage from '@/components/pages/web_development/WebApplicationsPage.vue'
import WebBannersPage from '@/components/pages/web_development/WebBannersPage.vue'
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
  },
  {
    path: '/augmented-reality',
    name: 'AugmentedReality',
    component: AugmentedRealityPage,
  },
  {
    path: '/3d-environmental',
    name: '3DEnvironmental',
    component: ThreeDEnvironmental,
  },
  {
    path: '/architectural-rfp',
    name: 'ArchitecturalRFP',
    component: EOPPresentationsPage,
  },
  {
    path: '/bd-presentations',
    name: 'BDPresentations',
    component: BDPresentationsPage,
  },
  {
    path: '/rfps',
    name: 'RFPs',
    component: RfpsPage,
  },
  {
    path: '/landing-pages',
    name: 'LandingPages',
    component: LandingPagesPage,
  },
  {
    path: '/web-banners',
    name: 'WebBanners',
    component: WebBannersPage,
  },
  {
    path: '/web-applications',
    name: 'WebApplications',
    component: WebApplicationsPage,
  },
  {
    path: '/personalized-video',
    name: 'PersonalizedVideo',
    component: PersonalizedVideoPage,
  },
  {
    path: '/movable-ink',
    name: 'MovableInk',
    component: MovableInkPage,
  },
  {
    path: '/credit-calculators',
    name: 'CreditCalculators',
    component: CreditCalculatorPage,
  },
  {
    path: '/digital-signage',
    name: 'DigitalSignage',
    component: DigitalSignagePage,
  },

  {
    path: '/qr-codes',
    name: 'QrCodes',
    component: QrCodesPage,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFoundPage,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router

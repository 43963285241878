<template>
  <section class="grid grid-cols-3 sm:grid-cols-6 gap-10 mt-10">
    <div class="col-span-3 flex flex-col gap-4 justify-center">
      <h3 class="font-bold text-3xl">Spectrum of Solutions</h3>
      <p class="mb-4">
        Utilizing MarTech in business development opens doors to a spectrum of
        solutions. From captivating <strong>landing pages</strong> and dynamic
        <strong>QR codes</strong> to targeted digital
        <strong>banner ads</strong> and personalized
        <strong>email campaigns</strong>, we harness these tools to enhance
        engagement and conversion.
      </p>
      <div class="flex flex-row gap-4 items-center">
        <img src="/stephens-bug.png" alt="" class="w-4" />
        <p class="text-xs italic">
          67% of mobile users say they’re more likely to buy a company’s product
          or service if its website is mobile-friendly. (Source:
          <a
            href="https://www.webfx.com/blog/web-design/user-experience-matters-marketing/"
            target="_blank"
            class="text-sd-blue underline"
            >Web FX</a
          >)
        </p>
      </div>
    </div>
    <div class="col-span-3">
      <img src="/presentation_3.1.png" alt="" />
    </div>
    <div class="col-span-3">
      <img src="/presentation_1.1.png" alt="" />
    </div>
    <div class="col-span-3 flex flex-col gap-4 justify-center">
      <h3 class="font-bold text-3xl">Expertise and Innovation</h3>
      <p class="mb-4">
        Our expertise extends to crafting compelling
        <strong>RFP responses</strong>, developing intuitive
        <strong>web apps</strong>, creating immersive
        <strong>3D environmental designs</strong>, and deploying impactful
        <strong>digital signage</strong> strategies.
      </p>
      <div class="flex flex-row gap-4 items-center">
        <img src="/stephens-bug.png" alt="" class="w-4" />
        <p class="text-xs italic">
          Companies that take a data-centered approach to marketing experience a
          15–20% higher return on investment. (Source:
          <a
            href="https://www.mckinsey.com/capabilities/growth-marketing-and-sales/our-insights/the-big-reset-data-driven-marketing-in-the-next-normal"
            target="_blank"
            class="text-sd-blue underline"
            >McKinsey</a
          >)
        </p>
      </div>
    </div>
    <div class="col-span-3 flex flex-col gap-4 justify-center">
      <h3 class="font-bold text-3xl">
        Driving Interactions and Elevating Brands
      </h3>
      <p class="mb-4">
        Together, these innovations drive meaningful interactions and elevate
        <strong>brand experiences</strong> across diverse digital landscapes.
      </p>
      <div class="flex flex-row gap-4 items-center">
        <img src="/stephens-bug.png" alt="" class="w-4" />
        <p class="text-xs italic">
          80% of businesses believe that marketing technology provides a
          competitive advantage. (Source:
          <a
            href="https://wifitalents.com/statistic/marketing-technology/"
            target="_blank"
            class="text-sd-blue underline"
            >Wifi Talents</a
          >)
        </p>
      </div>
    </div>
    <div class="col-span-3">
      <img src="/presentation_12.2.png" alt="" />
    </div>
    <div class="col-span-3">
      <img src="/presentation_9.1.png" alt="" />
    </div>
    <div class="col-span-3">
      <img src="/presentation_7.1.png" alt="" />
    </div>
  </section>
</template>

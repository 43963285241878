<template>
  <div class="slider-container grid grid-cols-1 lg:grid-cols-2 gap-4">
    <div>
      <img src="/PartnerBrand-CardArt.png" alt="Partner Brand" />
      <p class="heading">From shopping trips at Partner Brand to all your other everyday purchases, the rewards really add up!</p>
      <div class="counter-container">
        <p class="counter">
          <span style="font-size: 4rem; vertical-align: text-bottom">$</span><span>{{ total }}</span
          >.00
        </p>
        <p class="counter-subheading">Annual Rewards</p>
      </div>
    </div>
    <div class="sliders-container">
      <div class="slider" v-for="(slider, index) in sliders" :key="index">
        <div class="slider-input">
          <input class="w-full" type="range" v-model.number="slider.monthlySpend" min="0" max="800" step="1" @input="adjustWidth(numberInputs[index])" />
          <p>{{ slider.description }}</p>
        </div>
        <div class="slider-counter">
          <span style="font-size: 2.2rem"><sup>$</sup></span>
          <input :ref="(el) => (numberInputs[index] = el)" v-model.number="slider.monthlySpend" type="number" class="slider-heading" min="0" max="800" step="1" @input="handleInput(index)" />
          <p class="slider-subheading">Monthly Spend</p>
        </div>
      </div>
      <p class="closing">200 points = $5 reward*</p>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'

const sliders = ref([
  {
    monthlySpend: 75,
    description: 'Earn 5% back in rewards on Partner Brand purchases*',
  },
  {
    monthlySpend: 500,
    description: 'Earn 2.5% back in rewards on grocery & dining purchases*',
  },
  {
    monthlySpend: 300,
    description: 'Earn 2.5% back in rewards on gas purchases*',
  },
  {
    monthlySpend: 400,
    description: 'Earn 1.25% back in rewards on monthly bill payments*',
  },
])

const total = computed(() => {
  return Math.floor(Number(sliders.value[0].monthlySpend) * 0.05 + Number(sliders.value[1].monthlySpend) * 0.025 + Number(sliders.value[2].monthlySpend) * 0.025 + Number(sliders.value[3].monthlySpend) * 0.0125) * 12
})

const numberInputs = ref([])

const handleInput = (index) => {
  let value = sliders.value[index].monthlySpend
  if (value === '') {
    sliders.value[index].monthlySpend = 0
  } else if (value < 0) {
    sliders.value[index].monthlySpend = 0
  } else if (value > 800) {
    sliders.value[index].monthlySpend = 800
  }
  adjustWidth(numberInputs.value[index])
}

const adjustWidth = (element) => {
  const length = element.value.length
  const newWidth = length === 0 ? 2 : length
  element.style.width = `${newWidth}ch`
}

onMounted(() => {
  sliders.value.forEach((slider, index) => {
    adjustWidth(numberInputs.value[index])
  })
})
</script>

<style scoped>
div.slider-container {
  --primary-font-color: #3b3c43;
  --secondary-color: #fbc600;
  --primary-font-family: 'Synchrony Sans', sans-serif;
  --secondary-font-family: 'Synchrony Sans', sans-serif;
}

@font-face {
  font-family: 'Synchrony Sans';
  src: url('@/assets/SynchronySans.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Synchrony Sans Medium';
  src: url('@/assets/SynchronySans-Medium.woff') format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'Synchrony Sans Bold';
  src: url('@/assets/SynchronySans-Bold.woff') format('woff');
  font-weight: 600;
}

p {
  margin: 0;
}

img {
  width: 240px;
  filter: drop-shadow(rgba(0, 0, 0, 0.35) 0px 2px 6px);
  margin: 0 auto 32px;
  display: block;
}

input[type='range'] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 100%;
}

div.slider-container {
  width: 100%;
  color: var(--primary-font-color);
  box-sizing: border-box;
}

div.slider-container .heading {
  font-family: var(--primary-font-family);
  font-size: 1.5em;
  font-weight: 500;
  text-align: center;
  margin: 0 auto;
}

div.slider-container .counter-container {
  margin: 24px auto 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

div.slider-container .counter {
  font-family: var(--secondary-font-family);
  font-weight: 700;
  font-size: 5em;
  line-height: 7rem;
  text-align: center;
  display: inline;
  width: fit-content;
  padding-inline: 20px;
  border-radius: 10px;
  margin-bottom: 16px;
  background-color: var(--secondary-color);
}

div.slider-container .counter-subheading {
  font-family: var(--secondary-font-family);
  font-weight: 500;
  font-size: 1.25em;
  text-align: center;
}

div.slider-container .sliders-container .slider {
  display: flex;
  align-items: end;
  gap: 20px;
  margin-bottom: 24px;
}

div.slider-container .slider-input {
  flex-grow: 1;
  margin-bottom: 0.15rem;
}

div.slider-container .slider-counter {
  font-family: var(--secondary-font-family);
  text-align: center;
  min-width: 110px;
}

div.sliders-container .slider-counter input.slider-heading {
  font-family: var(--secondary-font-family);
  font-weight: 700;
  font-size: 3em;
  line-height: 3.85rem;
  background-color: transparent;
  color: var(--primary-font-color);
  border: none;
  text-align: center;
}

div.sliders-container .slider-counter input.slider-heading:focus-visible {
  outline: none;
}

div.sliders-container .slider-counter .slider-subheading {
  font-weight: 400;
  font-size: 1.15em;
}

div.sliders-container .slider-input p {
  margin-top: 20px;
  font-family: var(--secondary-font-family);
  text-align: center;
  font-weight: 400;
}

div.sliders-container .slider-input input {
  box-sizing: border-box;
  padding: 0 30px;
}

p.closing {
  text-align: center;
  font-family: var(--secondary-font-family);
  font-weight: 700;
  padding-top: 10px;
  padding-right: 22%;
  font-size: 1.25em;
}

/** Hide Spinners Number Input **/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

input[type='range']::-webkit-slider-runnable-track {
  background: var(--primary-font-color);
  height: 0.5rem;
  border-radius: 999px;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -8px; /* Centers thumb on the track */
  background-color: var(--secondary-color);
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 999px;
  border: 4px solid #fbf9f7;
}

input[type='range']::-moz-range-track {
  background: var(--primary-font-color);
  height: 0.5rem;
  border-radius: 999px;
}
</style>

<script setup>
import BarsIcon from '@/components/icons/BarsIcon.vue'
import XMarkIcon from '@/components/icons/XMarkIcon.vue'
import MenuLinks from '@/components/MenuLinks.vue'
import { ref, onMounted, watch, computed } from 'vue'
import PageBody from '@/components/PageBody.vue'
import pagesData from '@/assets/pagesData.json'
import { useRoute } from 'vue-router'
import { gsap } from 'gsap'

const route = useRoute()
const currentRoute = computed(() => route.name)
const pageData = ref({})
const menuOpen = ref(false)

const loadPageData = () => {
  const pageKey = route.name
  pageData.value = pagesData[pageKey] || {}
}

onMounted(loadPageData)
watch(route, loadPageData)

const close = () => (menuOpen.value = false)

const digitalExperienceLinks = ref([
  { text: 'Augmented Reality', to: '/augmented-reality' },
  { text: '3D & Environmental', to: '/3d-environmental' },
])

const businessDevelopmentLinks = ref([
  { text: 'BD Presentation', to: '/bd-presentations' },
  { text: 'Architectural RFP', to: '/architectural-rfp' },
  { text: 'RFPs', to: '/rfps' },
])

const webDevelopmentLinks = ref([
  { text: 'Web Banners', to: '/web-banners' },
  { text: 'Web Applications', to: '/web-applications' },
  { text: 'Landing Pages', to: '/landing-pages' },
  { text: 'Personalized Video', to: '/personalized-video' },
  { text: 'Movable Ink', to: '/movable-ink' },
  { text: 'Credit Calculators', to: '/credit-calculators' },
])

const analogExperienceLinks = ref([
  { text: 'Digital Signage', to: '/digital-signage' },
  { text: 'QR codes', to: '/qr-codes' },
])

const animateMenuIn = () => {
  const menuAnimation = gsap.to('.menu-button', {
    opacity: 100,
    duration: 100,
    ease: 'slow',
  })

  menuAnimation.play()
}

const playAnimation = (anim) => {
  if (!menuOpen.value) {
    return
  } else {
    anim.restart()
    anim.play()
  }
}

onMounted(() => {
  animateMenuIn()

  const menuButtonAnimation = gsap.to('.menu-button', {
    rotate: 180,
    duration: 0.3,
    paused: true,
    ease: 'spring',
    repeatRefresh: true,
    immediateRender: true,
  })

  document.querySelector('.menu-button').addEventListener('click', (e) => {
    e.preventDefault()
    playAnimation(menuButtonAnimation)
  })
})
</script>

<template>
  <div class="min-h-dvh w-dvw overflow-hidden">
    <div id="top" class="min-h-[36dvh] w-full md:overflow-hidden relative flex lg:justify-center" :class="menuOpen ? 'h-[100dvh]' : 'h-full'">
      <div class="max-w-7xl grid grid-cols-1 lg:grid-cols-2 mb-4 lg:mb-0 gap-16 px-8 lg:px-24 items-center relative">
        <div class="py-4 max-w-lg mr-auto z-[20]">
          <router-link to="/"><img src="/stephens-bug.png" class="w-14 mb-4" /></router-link>
          <h1 class="font-bold text-3xl mb-1">Stephens Direct</h1>
          <h2 class="font-bold text-xl mb-4">Digital Engagement Capabilities</h2>
          <p class="line-clamp-6 text-sm">Welcome to the future of digital marketing innovation. Our MarTech department harnesses cutting-edge technology to create captivating experiences that resonate with your audience. Explore our showcase to discover how we can transform your marketing strategy with creativity and precision.</p>
        </div>
        <div class="hidden lg:block z-[20]">
          <img src="/3d-digital-art.png" alt="" class="max-h-[36dvh] scale-x-[-1] w-auto mx-auto animate__animated animate__bounceIn" />
        </div>
        <button class="menu-button bg-[#12122C] fixed md:absolute z-50 top-3 right-3 lg:left-3 h-9 w-9 rounded-full inline-flex items-center justify-center opacity-0 hover:-translate-y-0.5 hover:drop-shadow-2xl" :class="menuOpen ? 'bg-white' : 'bg-[#12122C] hover:bg-gradient-to-b from-[#12122C] to-[#ED1F79] drop-shadow-2xl transition-all'" @click="menuOpen = !menuOpen">
          <BarsIcon v-if="!menuOpen" class="menu-icon text-xl text-white" />
          <XMarkIcon v-if="menuOpen" class="menu-icon text-xl text-[#12122C]" />
        </button>
      </div>
      <div v-if="menuOpen" class="menu fixed md:absolute bg-[#12122C] z-40 h-[100dvh] w-full overflow-hidden flex items-center md:justify-center top-0 left-0 bg-menu-bg bg-cover lg:bg-[length:auto_100vh] bg-right bg-no-repeat">
        <div class="max-w-7xl w-full px-8 lg:px-24 py-4 relative">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <MenuLinks title="Digital Experiences" :links="digitalExperienceLinks" :close="close" />
            <MenuLinks title="Analog Experiences" :links="analogExperienceLinks" :close="close" />
            <MenuLinks title="Web Development" :links="webDevelopmentLinks" :close="close" />
            <MenuLinks title="Business Development" :links="businessDevelopmentLinks" :close="close" />
          </div>
        </div>
      </div>
      <div id="overlay" class="absolute inset-0 bg-gradient-to-l from-transparent to-50% to-white z-10 bg-white bg-opacity-85 lg:bg-transparent lg:bg-opacity-100"></div>
      <div id="overlay" class="absolute inset-0 bg-gradient-to-b from-transparent from-60% to-white z-10"></div>
      <dotlottie-player id="lottie-player" class="absolute left-[35dvw] top-[-35dvh] h-[150dvh]" src="https://lottie.host/f14066b5-eaa8-4692-8a3c-8b6224ed93e6/qSaMR2yJja.json" background="transparent" speed="0.15" loop="true" autoplay></dotlottie-player>
    </div>
    <div id="bottom" class="min-h-[64dvh] relative" :class="currentRoute == 'Home' ? 'bg-sd-silver' : 'bg-sd-ice'">
      <div class="absolute top-0 left-0 w-full h-2 bg-gradient-to-r from-[#ED1F79] to-[#30C1D8]"></div>
      <PageBody v-if="pageData.title" :data="pageData">
        <router-view />
      </PageBody>
      <div v-else>
        <router-view />
      </div>
    </div>
    <div class="p-4 bg-sd-bg flex items-center flex-col">
      <img src="/stephens-bug.png" alt="Stephens Direct" class="w-10 border border-white rounded-full mb-2" />
      <p class="text-xs text-gray-400">© Stephens Direct. Proprietary and Confidential</p>
    </div>
  </div>
</template>

<style>
@tailwind base;
@tailwind components;
@tailwind utilities;

strong {
  font-weight: 600;
}
</style>

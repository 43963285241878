<script setup>
import { ref } from 'vue'
import ArrowRightIcon from '@/components/icons/ArrowRightIcon.vue'
import OpenIcon from '@/components/icons/OpenIcon.vue'

const videoPlayer = ref(null)
</script>

<template>
  <section class="grid grid-cols-3 sm:grid-cols-6 gap-10 mt-10">
    <div class="col-span-3 flex flex-col gap-4 justify-center">
      <h3 class="font-bold text-3xl">Engagement Power</h3>
      <p class="mb-4">The power of digital signage is in the engagement it offers. Not only does it capture attention, but it’s also a reusable and easily updated way to <strong>inform</strong>, <strong>entertain</strong>, and <strong>educate</strong>.</p>
      <h3 class="font-bold text-3xl">Flexibility</h3>
      <p class="mb-4">
        Digital signage is incredibly flexible. Ongoing updates can be handled by us or you with a custom
        <strong>content management system</strong> (CMS), based on whatever works best for your needs.
      </p>
      <div class="bg-gradient-to-r from-sd-bg from from-50% bg-[length:200%_100%] via-50% bg-left via-sd-blue to-sd-light-blue hover:bg-right transition-all duration-200 w-fit bg-clip-text text-lg">
        <p @click="videoPlayer.play()" class="text-transparent font-bold cursor-pointer inline-flex items-center gap-2">Play Example <ArrowRightIcon class="text-sd-bg" /></p>
      </div>
    </div>
    <div class="col-span-3 mb-2">
      <video ref="videoPlayer" controls>
        <source src="https://player.vimeo.com/progressive_redirect/playback/989530249/rendition/2160p/file.mp4?loc=external&log_user=0&signature=0ceb1243fa1c42b460764d7231a7bf8a8d626ca432243672bd39f8afd2f66a1f" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
    <div class="col-span-3 lg:col-span-2 mb-2">
      <img src="/hof.png" alt="" />
    </div>
    <div class="col-span-3 lg:col-span-4">
      <h3 class="font-bold text-2xl mb-1">Statistics</h3>
      <ul class="mb-4">
        <li class="mb-2">
          <strong class="text-lg">Increased Views</strong>
          <br />
          Digital displays attract <strong>4x as many views</strong>
          compared to static displays.
        </li>
        <li class="mb-2">
          <strong class="text-lg">Brand Awareness</strong>
          <br />
          Digital signage produces a <strong>47.7% increase</strong> in brand awareness and can lead to a <strong>46% increase</strong> in customer satisfaction.
        </li>
        <li class="mb-2">
          <strong class="text-lg">Advertising Recall</strong>
          <br />
          Digital advertising has an <strong>83% recall rate</strong>, which is <strong>2x as high</strong> as traditional advertising.
        </li>
        <li class="mb-2">
          <strong class="text-lg">Influence on Purchases</strong>
          <br />
          Digital signage influenced the purchasing decisions of
          <strong>68% of Americans</strong>.
        </li>
      </ul>
      <h3 class="font-bold text-2xl mb-1">PDF</h3>
      <router-link to="/Stephens_Branded_Digital_Signage.pdf" target="_blank" class="inline-flex items-center gap-2 hover:text-sd-blue"><span class="underline">Open PDF</span> <OpenIcon class="h-3 mb-[1px]" /></router-link>
    </div>
  </section>
</template>

<script setup>
import { defineProps } from 'vue'
import AngleIcon from '@/components/icons/AngleIcon.vue'
import HomeCategoryButton from './HomeCategoryButton.vue'

defineProps({
  item: Object,
})

const colSpanClass = (size) => {
  return {
    'lg:col-span-1': size === 1,
    'lg:col-span-2': size === 2,
    'lg:col-span-3': size === 3,
  }
}
</script>

<template>
  <div class="bg-sd-ice col-span-3 pl-10 pr-5 py-5 rounded-lg h-60 flex flex-row hover:scale-[101%] transition-transform duration-300 shadow-md" :class="colSpanClass(item.size)">
    <div class="flex flex-col items-start justify-between w-[352px]">
      <div>
        <div class="inline-flex text-xs items-center text-gray-500 font-[400] gap-1 order-2 lg:order-1 mb-6">
          <p class="select-none opacity-60">
            {{ item.category }}
          </p>
          <AngleIcon class="inline rotate-90 opacity-60 text-[10px] mt-0" />
          <p class="select-none opacity-60">
            {{ item.acronym ? item.acronym : item.title }}
          </p>
        </div>
        <h2 class="text-3xl font-bold mb-3">
          {{ item.acronym ? item.acronym : item.title }}
        </h2>
        <p class="font-bold text-lg mb-6">{{ item?.subtitle }}</p>
      </div>
      <HomeCategoryButton :to="item.url" />
    </div>
    <div class="flex-1 hidden lg:block bg-gray-300 h-full rounded-lg bg-cover bg-center" :style="{ backgroundImage: `url(${item?.image})` }"></div>
  </div>
</template>

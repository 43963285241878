<script setup></script>

<template>
  <section class="grid grid-cols-2 sm:grid-cols-6 gap-10 mt-10">
    <div class="col-span-3 flex flex-col gap-4 justify-center">
      <h3 class="font-bold text-3xl">Identifying Design Challenges</h3>
      <p class="mb-4">
        We excel at uncovering the complexities and obstacles inherent in 3D and
        environmental design projects. Our goal is to understand your specific
        challenges and provide effective solutions across a broad spectrum of
        capabilities, including design, visualization, and interactive
        experiences.
      </p>
      <h3 class="font-bold text-3xl">Bespoke Design Solutions</h3>
      <p class="mb-4">
        Our team focuses on creating tailored design solutions that meet your
        specific needs. Whether it’s an interactive presentation, environmental
        visualization, or motion graphics, our solutions are designed to deliver
        impactful results.
      </p>
    </div>
    <div class="col-span-3 mb-2">
      <img src="/nyc.png" alt="" />
    </div>
    <div class="col-span-3 mb-2">
      <iframe
        src="
https://my.spline.design/untitled-af1f1cbc5e1766baeadbe75a48a34b5f/"
        frameborder="0"
        width="100%"
        height="100%"
      ></iframe>
    </div>
    <div class="col-span-3 my-3">
      <h3 class="font-bold text-3xl mb-1">Benefits</h3>
      <ul>
        <li class="mb-2">
          <strong class="text-lg">Accessible Storytelling</strong>
          <br />
          Interactive elements make complex data more relatable and easier to
          digest for diverse audiences.
        </li>
        <li class="mb-2">
          <strong class="text-lg">Engagement</strong>
          <br />
          Interactive experiences encourage audience participation, increasing
          interest and retention of information.
        </li>
        <li class="mb-2">
          <strong class="text-lg">Improved Communication</strong>
          <br />
          Visuals help convey ideas more clearly to stakeholders, clients, and
          the public.
        </li>
        <li class="mb-2">
          <strong class="text-lg">Comprehensive Context</strong>
          <br />
          3D visualization can integrate surrounding environments, showcasing
          how projects fit within their environment.
        </li>
      </ul>
    </div>
  </section>
</template>

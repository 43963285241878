<template>
  <svg
    width="1.65em"
    viewBox="0 0 21 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.018 2.82367L16.3388 0.699457L16.3388 4.94788L20.018 2.82367ZM0.719727 3.19159L16.7067 3.1916L16.7067 2.45575L0.719727 2.45574L0.719727 3.19159Z"
    />
  </svg>
</template>

<style scoped>
svg {
  fill: currentColor;
}
</style>

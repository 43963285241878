<script setup>
import { defineProps, defineEmits, computed } from 'vue';

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue', 'change']);

const checked = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val),
});

const toggle = () => {
  emit('change', checked.value);
};
</script>

<template>
  <label class="relative inline-block w-14 h-8">
    <input
      type="checkbox"
      class="opacity-0 w-0 h-0"
      v-model="checked"
      @change="toggle"
    />
    <span
      class="absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-sd-bg rounded-full transition duration-300 before:absolute before:content-[''] before:h-6 before:w-6 before:left-1 before:bottom-1 before:bg-white before:rounded-full before:transition before:duration-300"
      :class="{ 'before:translate-x-6': checked }"
    ></span>
  </label>
</template>

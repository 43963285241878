<template>
  <section class="grid grid-cols-3 lg:grid-cols-6 gap-10 mt-10">
    <div class="col-span-3 flex flex-col gap-4 justify-center">
      <h3 class="font-bold text-2xl">Streamlined Development Timelines</h3>
      <p class="mb-4">Creating and maintaining landing pages with us is incredibly easy and efficient. Our intuitive tools and user-friendly interfaces allow you to make updates and changes swiftly, ensuring that your content remains fresh and relevant. Whether you’re adding new features, adjusting the design, or updating information, our platform simplifies the process, saving you time and effort.</p>
      <h3 class="font-bold text-2xl">Comprehensive Analytics and Reporting</h3>
      <p class="mb-4">We understand the importance of data-driven decision-making, which is why we provide comprehensive analytics quickly and accurately. Our robust data offers real-time insights into user behavior, conversion rates, and overall performance. This enables you to make informed decisions and optimize your landing pages for maximum impact without delay.</p>
    </div>
    <div class="col-span-3 mb-2">
      <img src="/lp_verizon.png" alt="" />
    </div>
    <div class="col-span-3 mb-2">
      <img src="/lp_bpfuel.png" alt="" />
    </div>
    <div class="col-span-3 flex flex-col gap-4 justify-center">
      <h3 class="font-bold text-2xl">Rapid Content Updates</h3>
      <p class="mb-4">We pride ourselves on our ability to work quickly and communicate effectively. By cutting through the red tape and streamlining our processes, we ensure that your projects are completed faster and more efficiently.</p>
      <h3 class="font-bold text-2xl">Why a Landing Page?</h3>
      <p class="mb-4">Landing pages are distinct from regular web pages in that they are specifically designed to drive conversions and achieve a single objective, such as capturing leads, promoting a product, or encouraging sign-ups. Unlike typical web pages, which provide a broad range of information and navigation options, landing pages focus on simplicity and clarity, presenting a clear call to action without distractions. Utilizing landing pages allows you to target specific audiences with tailored messages, resulting in higher conversion rates and more effective marketing campaigns. By directing users to a focused and goal-oriented page, you can better guide their actions and achieve your business objectives.</p>
    </div>
  </section>
</template>

<script setup>
import { ref } from 'vue';
import ArrowRightIcon from '@/components/icons/ArrowRightIcon.vue';

const videoPlayer = ref(null);
</script>

<template>
  <section class="grid grid-cols-3 lg:grid-cols-6 gap-10 mt-10">
    <div class="col-span-3 flex flex-col gap-4 justify-center">
      <h3 class="font-bold text-3xl">Interactive RFP Demonstration</h3>
      <p class="mb-4">
        We’ve created an <strong>online presentation</strong> that effectively
        demonstrates what an <strong>interactive RFP</strong> might look like
        for an architecture firm. This presentation not only outlines the
        essential components of the RFP but does so in an engaging way that
        brings the story to life.
      </p>
      <div
        class="bg-gradient-to-r from-sd-bg from from-50% bg-[length:200%_100%] via-50% bg-left via-sd-blue to-sd-light-blue hover:bg-right transition-all duration-200 w-fit bg-clip-text text-lg"
      >
        <p
          @click="videoPlayer.play()"
          class="text-transparent font-bold cursor-pointer inline-flex items-center gap-2"
        >
          Play Example <ArrowRightIcon class="text-sd-bg" />
        </p>
      </div>
    </div>
    <div class="col-span-3 mb-2">
      <video ref="videoPlayer" controls>
        <source
          src="https://player.vimeo.com/progressive_redirect/playback/990301344/rendition/1080p/file.mp4?loc=external&log_user=0&signature=15d14648f699c89cf079c276d544c4f6c1118201b8ecafee98e7f705244d46ad"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </div>
    <div class="col-span-3">
      <h3 class="font-bold text-2xl">Engaging Presentation</h3>
      <p class="mb-4">
        We made heavy use of <strong>animation and interactivity</strong> to
        guide the user and provide information in an engaging way that showcases
        elements of both the interior and exterior design.
      </p>
    </div>
    <div class="col-span-3">
      <h3 class="font-bold text-2xl">Interactive Call-Outs</h3>
      <p class="mb-4">
        We also made use of interactive call-outs that break down complex
        concepts, making them intuitive and easy to understand.
      </p>
    </div>
  </section>
</template>
